import { Screen, Notify } from "quasar";
import { apiHost } from "src/utils/constants";
import { RouterView } from "vue-router";
import { Store } from "src/store";

import terms from "assets/policy/terms.txt";
import privacy from "assets/policy/privacy.txt";
import { isLoggedIn, checkFeatureAccess, isOwner, getStoreUrl, checkPermission } from "src/utils";

import { i18n } from "boot/i18n";
const $t = i18n.global.t;

const routes = [
  {
    path: "/:locale",
    component: () => import("src/App.vue"),
    beforeEnter: (to, _from, next) => {
      // If no proper locale push to en
      if (!["en", "ar"].includes(to.params.locale)) {
        let fallbackAttemptPath = "/en" + to.path;
        next({
          query: to.query,
          path: fallbackAttemptPath,
        });
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        name: "auth",
        component: () => import("layouts/MainLayout.vue"),
        children: [
          {
            path: "",
            name: "signUp",
            component: () => import("pages/Auth/Register.vue"),
            beforeEnter: (to, _from, next) => {
              if (isLoggedIn()) {
                next({ ...to, name: "home" });
              } else {
                next();
              }
            },
            meta: {
              step: 0,
              event: "register_page",
            },
          },
          {
            path: "otp",
            name: "otp",
            component: () => import("pages/Auth/OTP.vue"),
            meta: {
              step: 1,
              event: "customer_entering_otp",
            },
          },
          {
            path: "create-password",
            name: "createPassword",
            component: () => import("pages/Auth/CreatePassword.vue"),
            meta: {
              step: 1,
              event: "customer_creating_password",
            },
          },
          {
            path: "enter-password",
            name: "enterPassword",
            component: () => import("pages/Auth/EnterPassword.vue"),
            meta: {
              step: 1,
            },
          },
          {
            path: "auth-success",
            component: () => import("src/pages/Auth/AuthRedirect.vue"),
            meta: {
              step: 2,
              event: "customer_finished_social_authentication",
            },
          },
          {
            path: "finish-signup",
            name: "finishSignup",
            component: () => import("pages/Auth/FinishSignUp.vue"),
            meta: {
              step: 2,
              event: "customer_filling_more_data",
            },
          },
          {
            path: "account-detected",
            name: "accountDetected",
            component: () => import("pages/Auth/AccountDetected.vue"),
            meta: {
              step: 1,
            },
          },
          {
            path: "forgot-password/1",
            name: "forgotPassword1",
            component: () => import("pages/Auth/ForgotPassword1.vue"),
            meta: {
              step: 2,
            },
          },
          {
            path: "forgot-password/2",
            name: "forgotPassword2",
            component: () => import("pages/Auth/ForgotPassword2.vue"),
            meta: {
              step: 3,
            },
          },
          {
            path: "users/password/edit",
            name: "updatePassword",
            component: () => import("pages/Auth/UpdatePassword.vue"),
            meta: {
              step: 4,
            },
          },
          {
            path: "sign-out",
            name: "signOut",
            beforeEnter: (to, _from, next) => {
              Store.commit("auth/signOut");
              Store.reset();
              next(`/${to.params.locale}`);
            },
          },
        ],
      },
      {
        path: "congratulations",
        name: "creationLoading",
        meta: {
          event: "customer_finished_creating_store",
        },
        component: () => import("pages/Onboarding/CreationLoading.vue"),
      },
      {
        // master admin / super admin
        name: "railsAdmin",
        path: "railsAdmin",
        beforeEnter: (_from, _next) => {
          location.href = `${apiHost}/admin`;
        },
      },
      {
        path: "store-creation",
        component: () => import("layouts/StoreCreationLayout.vue"),
        beforeEnter: (_to, _from, next) => {
          if (isLoggedIn()) {
            next({ name: "home" });
          } else {
            next();
          }
        },
        children: [
          {
            path: "",
            name: "storeCreation",
            component: () => import("pages/Onboarding/StoreCreation.vue"),
            meta: {
              step: 0,
              event: "customer_started_creating_store",
            },
          },
          {
            path: "store-info",
            component: () => import("layouts/StoreInfoLayout.vue"),
            children: [
              {
                path: "1",
                name: "storeInfo1",
                component: () => import("pages/Onboarding/StoreInfo1.vue"),
                meta: {
                  step: 1,
                  event: "customer_entering_store_industry",
                },
              },
            ],
          },
        ],
      },
      {
        path: "plan",
        name: "Plan",
        component: () => import("src/layouts/PlanLayout.vue"),
        beforeEnter: (to, _from, next) => {
          if (isLoggedIn()) {
            next();
          } else {
            next(`/${to.params.locale}`);
          }
        },
        children: [
          {
            name: "renew_plan",
            path: "renew-plan",
            components: {
              default: () => import("src/pages/Plan/RenewUpgradePlan.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "renew_plan",
            },
          },
          {
            name: "upgrade_plan",
            path: "upgrade-plan",
            components: {
              default: () => import("src/pages/Plan/RenewUpgradePlan.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "upgrade_plan",
            },
          },
          {
            name: "pick_plan",
            path: "pick-plan",
            event: "customer_choosing_plan",
            components: {
              default: () => import("src/pages/Plan/PickPlan.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "pick_plan",
            },
          },
          {
            name: "add_address",
            path: "add-address",
            event: "customer_adding_billing_address",
            components: {
              default: () => import("src/pages/Plan/AddAddress.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "add_address",
            },
          },
          {
            name: "subscribe_plan",
            path: "subscribe",
            event: "customer_paying",
            components: {
              default: () => import("src/pages/Plan/Subscribe.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "subscribe",
            },
          },
          {
            name: "plan_cod",
            path: "plan-cod",
            components: {
              default: () => import("src/pages/Plan/CashOnDelivery.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "payment_successful",
            },
          },
          {
            name: "payment-successful",
            path: "payment-successful",
            components: {
              default: () => import("src/pages/Plan/PaymentSuccessful.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "payment_successful",
            },
          },
        ],
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("layouts/DashboardLayout.vue"),
        beforeEnter: (to, _from, next) => {
          if (isLoggedIn()) {
            next();
          } else {
            next(`/${to.params.locale}`);
          }
        },
        children: [
          {
            path: "home",
            name: "home",
            components: {
              default: () =>
                Store.getters["auth/isNewShop"]
                  ? import("pages/Dashboard/Home/FreshSignUps.vue")
                  : import("pages/Dashboard/Home/Home.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "home",
            },
          },
          {
            path: "updates",
            name: "updates",
            components: {
              default: () => import("pages/Dashboard/Updates/AllUpdates.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "updates",
            },
          },

          {
            path: "products",
            components: {
              default: () => import("pages/Dashboard/Products/AllProducts.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "products",
            },
            children: [
              {
                name: "all_products",
                path: "",
                component: () => import(`pages/Dashboard/Products/ProductsTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            name: "orders",
            path: "orders",
            components: {
              default: () => import("pages/Dashboard/Orders/AllOrders.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "orders",
            },
            children: [
              {
                name: "all_orders",
                path: "",
                component: () =>
                  import(`pages/Dashboard/Orders/components/OrdersTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            name: "drafts",
            path: "drafts",
            components: {
              default: () => import("pages/Dashboard/Orders/Drafts.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "orders",
            },
            children: [
              {
                name: "draft_orders",
                path: "",
                component: () =>
                  import(`pages/Dashboard/Orders/components/DraftsTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            name: "customer_details",
            path: "customer/:id",
            props: true,
            components: {
              default: () => import("pages/Dashboard/Customers/CustomerDetails.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "customer_details",
            },
            props: {
              header: () => ({ hideSideBarIcon: false, showBackButton: true }),
            },
          },
          {
            name: "analytics",
            path: "analytics",
            beforeEnter: (to, _from, next) => {
              checkFeatureAccess(["shop-analytics", "pos-analytics"], next);
            },
            components: {
              default: () => import("pages/Dashboard/Analytics/Analytics.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "analytics",
            },
          },
          {
            name: "online_analytics",
            path: "online-analytics",
            beforeEnter: (to, _from, next) => {
              checkFeatureAccess(["shop-analytics"], next);
            },
            components: {
              default: () => import("src/pages/Dashboard/Analytics/OnlineAnalytics.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "online_analytics",
            },
          },
          {
            name: "zam_pos_analytics",
            path: "zam-pos-analytics",
            beforeEnter: (to, _from, next) => {
              checkFeatureAccess(["pos-analytics"], next);
            },
            components: {
              default: () => import("src/pages/Dashboard/Analytics/ZamPosAnalytics.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "zam_pos_analytics",
            },
          },
          {
            name: "discounts",
            path: "discounts",
            beforeEnter: (to, _from, _next) => {
              location.href = `${apiHost}/discounts?locale=${to.params.locale}`;
            },
          },
          {
            name: "website-builder",
            path: "website-builder",
            beforeEnter: (to, _from, next) => {
              const customNext = () => {
                location.href = `https://builder.zammit.shop/${to.params.locale}`;
              };
              // if (checkPermission("websiteBuilder")) {
              checkFeatureAccess(["theming"], next, customNext);
              // } else {
              //   Notify.create({
              //     type: "warning",
              //     message: $t("general.unauthorized_access"),
              //   });
              // }
            },
          },
          {
            name: "plan_details",
            path: "plan-details",
            components: {
              default: () => import("src/pages/Plan/PlanDetails.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "plan_details",
            },
          },
          {
            name: "partners",
            path: "partners",
            components: {
              default: () => import("pages/Dashboard/Partners/PartnersHome.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "partners",
            },
          },
          {
            name: "partner",
            path: "partner/:type",
            props: true,
            components: {
              default: () => import("pages/Dashboard/Partners/PartnersDetails"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "partners",
            },
          },
          {
            name: "staff_management",
            path: "staff-management",
            beforeEnter: (to, _from, next) => {
              next({ name: "users_and_permissions" });
            },
          },
          {
            name: "store",
            path: "store",
            beforeEnter: (to, _from, _next) => {
              window.open(`https://${getStoreUrl(Store.state.auth.company)}`, "_blank");
            },
          },
          {
            name: "add_product",
            path: "add-product",
            components: {
              default: () => import("pages/Dashboard/Products/AddProduct"),
              header: () => import("layouts/AddItemHeader.vue"),
            },
            meta: {
              object: "product",
            },
          },
          {
            name: "add_collection",
            path: "add-collection",
            components: {
              default: () => import("pages/Dashboard/Collections/AddCollection"),
              header: () => import("layouts/AddItemHeader.vue"),
            },
            meta: {
              object: "collection",
            },
          },
          {
            name: "create_order",
            path: "new-order/:id",
            components: {
              default: () => import("pages/Dashboard/Orders/AddOrder"),
              header: () => import("pages/Dashboard/Orders/components/AddOrderHeader.vue"),
            },
          },
          {
            name: "order_details",
            path: "order/:id",
            components: {
              default: () => import("pages/Dashboard/Orders/OrderDetails"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "orders",
            },
          },
          {
            name: "product_details",
            path: "product/:id",
            components: {
              default: () => import("pages/Dashboard/Products/ProductDetails"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "products",
            },
          },
          {
            name: "collection_details",
            path: "collection/:id",
            components: {
              default: () => import("pages/Dashboard/Collections/CollectionDetails"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "collections",
            },
          },
          {
            name: "variant_details",
            path: "product/:id/variants",
            props: true,
            components: {
              default: () => import("pages/Dashboard/Products/VariantDetails"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "products",
            },
          },
          {
            name: "new_variant_details",
            path: "product/:id/new-variant",
            props: true,
            components: {
              default: () => import("pages/Dashboard/Products/VariantDetails"),
              header: () => import("pages/Dashboard/Products/components/AddVariantHeader.vue"),
            },
          },
          {
            name: "add_discount",
            path: "add-discount",
            components: {
              default: () => import("pages/Dashboard/Discounts/AddDiscount"),
              header: () => import("layouts/AddItemHeader.vue"),
            },
            meta: {
              object: "discount",
            },
          },
          {
            name: "discount_details",
            path: "discount/:id",
            components: {
              default: () => import("pages/Dashboard/Discounts/DiscountDetails"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "discounts",
            },
          },
          {
            name: "collections",
            path: "collections",
            components: {
              default: () => import("pages/Dashboard/Collections/AllCollections.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "collections",
            },
            children: [
              {
                name: "all_collections",
                path: "",
                component: () =>
                  import(`pages/Dashboard/Collections/CollectionsTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            path: "inventory",
            components: {
              default: () => import("src/pages/Dashboard/Inventory/Inventory.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "inventory",
            },
            children: [
              {
                name: "inventory",
                path: "",
                component: () =>
                  import(`pages/Dashboard/Inventory/InventoryTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            path: "related-products",
            components: {
              default: () => import("src/pages/Dashboard/RelatedProducts/AllProducts.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "related_products",
            },
            children: [
              {
                name: "related_products",
                path: "",
                component: () =>
                  import(`pages/Dashboard/RelatedProducts/ProductsTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            path: "pricing",
            components: {
              default: () => import("src/pages/Dashboard/Pricing/Pricing.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "pricing",
            },
            children: [
              {
                name: "pricing",
                path: "",
                component: () => import(`pages/Dashboard/Pricing/PricingTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            name: "add-to-jumia",
            path: "add-to-jumia",
            components: {
              default: () => import("src/pages/Dashboard/Jumia/AllProducts.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "add_to_jumia",
            },
            children: [
              {
                name: "add_to_jumia",
                path: "",
                component: () => import(`pages/Dashboard/Jumia/ProductsTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            path: "instagram/select",
            name: "instagram_select",
            components: {
              default: () => import("src/pages/Dashboard/Instagram/Select.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "instagram_import",
            },
          },
          {
            name: "instagram_redirect",
            path: "instagram/redirect",
            components: {
              default: () => import("src/pages/Dashboard/Instagram/InstagramRedirect.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "instagram_import",
            },
          },
          {
            path: "discounts",
            components: {
              default: () => import("src/pages/Dashboard/Discounts/AllDiscounts.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "discounts",
            },
            children: [
              {
                name: "discounts",
                path: "",
                component: () =>
                  import(`pages/Dashboard/Discounts/DiscountTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            name: "navigation",
            path: "navigation",
            components: {
              default: () => import("pages/Dashboard/Navigation/Navigation.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "navigation",
            },
          },
          {
            path: "customers",
            components: {
              default: () => import("src/pages/Dashboard/Customers/AllCustomers.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "all_customers",
            },
            children: [
              {
                name: "customers",
                path: "",
                component: () =>
                  import(`pages/Dashboard/Customers/CustomersTable${Screen.lt.sm ? "Mobile" : "Web"}.vue`),
              },
            ],
          },
          {
            name: "send-sms",
            path: "send-sms",
            components: {
              default: () => import("pages/Dashboard/iframes/SMS.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "send_sms",
            },
          },
          {
            name: "borrow-and-grow",
            path: "borrow-and-grow",
            components: {
              default: () => import("src/pages/Dashboard/BorrowAndGrow/BorrowAndGrow.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "borrow_and_grow",
            },
          },
          {
            name: "borrow-and-grow-iframe",
            path: "borrow-and-grow/:partner",
            components: {
              default: () => import("pages/Dashboard/iframes/BorrowAndGrowIframe.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "borrow_and_grow",
            },
          },
          {
            name: "paymob-self-onboarding",
            path: `paymob-self-onboarding`,
            components: {
              default: () => import("pages/Dashboard/iframes/PaymobSelfOnboarding.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: "paymob_self_onboarding",
            },
          },
          {
            name: "add_customer",
            path: "add-customer",
            components: {
              default: () => import("pages/Dashboard/Customers/AddCustomer"),
              header: () => import("layouts/AddItemHeader.vue"),
            },
            meta: {
              object: "customer",
            },
          },
          {
            name: "500",
            path: "500",
            props: true,
            components: {
              default: () => import("pages/Error500.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: (route) => route.params.headerTitle,
          },

          // Coming soon routes
          ...["e-tax"].map((routeName) => ({
            name: routeName,
            path: routeName,
            components: {
              default: () => import("pages/ComingSoon.vue"),
              header: () => import("layouts/DefaultHeader.vue"),
            },
            meta: {
              headerTitle: routeName,
            },
          })),
        ],
      },
      {
        path: "khazenly-auth-redirect",
        component: () => import("src/pages/Dashboard/Settings/ShippingAndDelivery/KhazenlyAuthRedirect.vue"),
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("layouts/SettingsLayout.vue"),
        children: [
          {
            name: "general_settings",
            path: "general-settings",
            component: () => import("src/pages/Dashboard/Settings/GeneralSettings/GeneralSettings"),
            meta: {
              headerTitle: "general_settings",
            },
          },
          {
            name: "checkout_settings",
            path: "checkout-settings",
            component: () => import("src/pages/Dashboard/Settings/CheckoutSettings/CheckoutSettingsForm"),
            meta: {
              headerTitle: "checkout_settings",
            },
          },

          {
            name: "payment",
            path: "",
            component: RouterView,
            children: [
              {
                name: "paymob_integrations",
                path: "paymob",
                component: () => import("src/pages/Dashboard/Settings/Payment/Paymob.vue"),
              },
              {
                name: "stripe_integrations",
                path: "stripe",
                component: () => import("src/pages/Dashboard/Settings/Payment/Stripe.vue"),
              },
              {
                name: "geidea_integrations",
                path: "geidea",
                component: () => import("src/pages/Dashboard/Settings/Payment/Geidea.vue"),
              },
              {
                name: "gccpay_integrations",
                path: "gccpay",
                component: () => import("src/pages/Dashboard/Settings/Payment/Gccpay.vue"),
              },
            ],
            meta: {
              headerTitle: "payment",
            },
          },
          {
            name: "integrations",
            path: "",
            component: RouterView,
            children: [
              {
                name: "payment_integrations",
                path: "payment",
                component: () => import("src/pages/Dashboard/Settings/Payment/Paymob.vue"),
              },
              {
                name: "chat_integrations",
                path: "chat",
                component: () => import("pages/Dashboard/Settings/Integrations/Chat.vue"),
              },
              {
                name: "marketing_integrations",
                path: "marketing",
                component: () => import("pages/Dashboard/Settings/Integrations/EmailMarketing.vue"),
              },
              {
                name: "analytics_integrations",
                path: "analytics",
                component: () => import("pages/Dashboard/Settings/Integrations/Analytics.vue"),
              },
              {
                name: "loyalty_integrations",
                path: "loyalty",
                component: () => import("pages/Dashboard/Settings/Integrations/Loyalty.vue"),
              },
            ],
            meta: {
              headerTitle: "integrations",
            },
          },
          {
            name: "users_and_permissions",
            path: "users",
            component: () => import("src/pages/Dashboard/Settings/UsersAndPermissions/StaffManagement.vue"),
            meta: {
              headerTitle: "users_and_permissions",
            },
          },
          {
            name: "social_media_links",
            path: "social-media",
            component: () => import("src/pages/Dashboard/Settings/SocialMediaPlatforms/Info.vue"),
            meta: {
              headerTitle: "social_media_links",
            },
          },
          {
            name: "shipping",
            path: "",
            component: RouterView,
            children: [
              {
                name: "shipping_details",
                path: "shipping",
                component: () => import("src/pages/Dashboard/Settings/ShippingAndDelivery/ShippingTabs.vue"),
              },
            ],
            meta: {
              headerTitle: "shipping",
            },
          },
          {
            name: "shop_customization",
            path: "shop-customization",
            component: () => import("pages/Dashboard/Settings/ShopCustomization/ShopCustomization.vue"),
            meta: {
              headerTitle: "shop_customization",
            },
          },
          {
            name: "advanced_settings",
            path: "advanced-settings",
            component: () => import("pages/Dashboard/Settings/AdvancedSettings/AdvancedSettings.vue"),
            meta: {
              headerTitle: "advanced_settings",
            },
          },
          {
            name: "policies",
            path: "policies",
            component: () => import("pages/Dashboard/Settings/Policies/Policies.vue"),
            meta: {
              headerTitle: "policies",
            },
          },
        ],
      },
      {
        path: "terms",
        component: () => import("layouts/PolicyLayout.vue"),
        name: "Terms",
        props: {
          title: "TERMS AND CONDITIONS",
          body: terms,
        },
      },
      {
        path: "privacy",
        component: () => import("layouts/PolicyLayout.vue"),
        name: "Privacy",
        props: {
          title: "PRIVACY POLICY",
          body: privacy,
        },
      },
    ],
  },
  {
    name: "404",
    path: "/:catchAll(.*)*",
    beforeEnter: (to, _from, next) => {
      // Check if no locale param passed or if the first param passed is not a valid locale
      if (!to.params.catchAll[0] || !["en", "ar"].includes(to.params.catchAll[0])) {
        let fallbackAttemptPath = "/en" + to.path;
        next({ query: to.query, path: fallbackAttemptPath });
      } else {
        next();
      }
    },
    component: () => import("pages/Error404.vue"),
  },
];

export default routes;
