import { api } from "boot/axios";
import {
  formatCompany,
  formatAddressToAPI,
  formatAddressFromAPI,
  formatCompanyDetails,
  formatCompanyFields,
  formatFields,
  formatStoreFeatures,
} from "./serializers/companySerializer";

const companySettingsPrefix = "company";
const platformsPrefix = "platforms";
const apiPrefix = "api/v2";
const companyPrefix = "companies";
const storeFeaturesPrefix = "store_feature";
const companyLinksPrefix = "company_links";
const CompanyAPI = {
  async createCompany(company, user) {
    const formattedCompany = formatCompany(company, user);
    const response = await api.post(`${apiPrefix}/${companyPrefix}`, formattedCompany);
    return response.data.data;
  },

  async checkSubdomain(subdomain) {
    const response = await api.post(`${apiPrefix}/${companyPrefix}/check_subdomain`, { subdomain });
    return response.data.found;
  },

  async getCompanyDetails(view) {
    const response = await api.get(`${apiPrefix}/company`, {
      params: {
        view,
        includes: JSON.stringify([{ relation: "checkout_customizations" }]),
      },
    });

    let data = response.data.data;
    if (data.shippingAddress) data.shippingAddress = formatAddressFromAPI(data.shippingAddress);
    if (data.billingAddress) data.billingAddress = formatAddressFromAPI(data.billingAddress);
    return data;
  },

  async updateCompany(company) {
    if (company.address) {
      if (Array.isArray(company.address)) {
        const addressArray = company.address.map((a) => formatAddressToAPI(a));
        company.address = addressArray;
      } else {
        company.address = formatAddressToAPI(company.address);
      }
    }
    const response = await api.patch(`${apiPrefix}/company`, company, {
      params: { view: "details" },
    });
    let data = response.data.data;
    if (data.shippingAddress) data.shippingAddress = formatAddressFromAPI(data.shippingAddress);
    if (data.billingAddress) data.billingAddress = formatAddressFromAPI(data.billingAddress);
    return data;
  },
  async updateCompanyFields(data) {
    const payload = Array.isArray(data) ? formatCompanyFields(data) : formatFields(data);
    const response = await api.put(`${apiPrefix}/${companySettingsPrefix}`, payload, {
      params: {
        view: "details",
      },
    });
    return response;
  },

  async updateCompanyFieldsValues(data) {
    const response = await api.put(`${apiPrefix}/${companySettingsPrefix}`, data, {
      params: {
        view: "details",
      },
    });
    return response;
  },

  async updateCompanyAddress(data) {
    const payload = {
      address: [formatAddressToAPI(data)],
    };
    const response = await api.put(`${apiPrefix}/${companySettingsPrefix}`, payload, {
      params: {
        view: "details",
      },
    });

    return response;
  },

  async changeCompanyAddress(data) {
    const payload = {
      address: data,
    };
    const response = await api.put(`${apiPrefix}/${companySettingsPrefix}`, payload, {
      params: {
        view: "details",
      },
    });
    return response;
  },

  async updateCompanyPlatforms(data) {
    const response = await api.put(`${apiPrefix}/${companySettingsPrefix}`, data, {
      params: {
        view: "details",
      },
    });
    return response.data.data.companyLinks;
  },

  async updateCompanyPlatforms(data) {
    const response = await api.put(`${apiPrefix}/${companySettingsPrefix}`, data, {
      params: {
        view: "details",
      },
    });
    return response.data.data.companyLinks;
  },

  async fetchCompanyDetails(geolocations) {
    const response = await api.get(`${apiPrefix}/${companySettingsPrefix}`, {
      headers: {
        locale: "all",
      },
      params: {
        view: "details",
        ...(geolocations && {
          includes: JSON.stringify([{ relation: "geolocations" }]),
        }),
      },
    });
    const formattedData = formatCompanyDetails(response.data.data);
    return formattedData;
  },

  async fetchCompanyDetailsWithGeolocations() {
    const response = await api.get(`${apiPrefix}/${companySettingsPrefix}`, {
      params: {
        view: "details",
        includes: JSON.stringify([{ relation: "geolocations" }]),
      },
    });
    const formattedData = formatCompanyDetails(response.data.data);
    return formattedData;
  },

  // New patterns is to return data as is to keep BE and FE in match and avoid unnecessary formatting on the client
  // should refactor all the similar methods to use this, then remove the _v2 postfix
  async getCompanyDetails_v2(includes = []) {
    const includeHeader = JSON.stringify(includes.map((relation) => ({ relation })));

    const response = await api.get(`${apiPrefix}/${companySettingsPrefix}`, {
      params: {
        view: "details",
        includes: includeHeader,
      },
    });

    return response.data.data;
  },

  async fetchPlatforms() {
    const response = await api.get(`${apiPrefix}/${platformsPrefix}`);
    return response.data.data;
  },

  async deleteCompanyPlatform(id) {
    const response = await api.delete(`${apiPrefix}/${companySettingsPrefix}/${companyLinksPrefix}`, {
      params: { id },
    });
    return response.data.success;
  },

  async getStoreFeatures() {
    const response = await api.get(`${apiPrefix}/${storeFeaturesPrefix}`);
    const formattedData = formatStoreFeatures(response.data.storeFeatures);
    return formattedData;
  },

  async addStoreFeatures(data) {
    const response = await api.put(`${apiPrefix}/${companySettingsPrefix}`, data);
    return response.data.data.storeFeatures;
  },

  async updateStoreFeatures(data) {
    const selectedStoreFeatures = {
      store_feature_ids: data.map((item) => item.id),
    };
    const response = await api.put(`${apiPrefix}/${companySettingsPrefix}`, selectedStoreFeatures);
    return response.data.data.storeFeatures;
  },

  async updateStoreFeaturesKey(data) {
    const requestData = {
      storeFeaturesKeys: data,
    };
    const response = await api.put(`${apiPrefix}/companies/update_store_features_key`, requestData);
    return response.data.data.storeFeaturesKeys;
  },

  async updateCheckoutCustomization({ checkoutCustomization }) {
    const response = await api.put(`${apiPrefix}/checkout_customizations/${checkoutCustomization.id}`, {
      labelEn: checkoutCustomization.labelEn,
      labelAr: checkoutCustomization.labelAr,
      isVisible: checkoutCustomization.isVisible,
      key: checkoutCustomization.key,
    });
    return response.data.data.checkoutCustomoizations;
  },

  async bulkUpdateCheckoutCustomizations({ checkoutCustomizations }) {
    const response = await api.put(`${apiPrefix}/checkout_customizations/bulk_update`, {
      checkoutCustomizations,
    });
    return response.data;
  },

  async bulkUpdateVariantsPrices({
    priceCentsChange,
    isPercentagePriceCentsChange,
    discountCentsChange,
    isPercentageDiscountCentsChange,
  }) {
    const response = await api.put(`${apiPrefix}/variants/variants_prices/`, {
      priceCentsChange,
      isPercentagePriceCentsChange,
      discountCentsChange,
      isPercentageDiscountCentsChange,
    });
    return response.data;
  },

  async getCheckoutCustomizations() {
    const response = await api.get(`${apiPrefix}/checkout_customizations/`, {
      headers: {
        locale: "all",
      },
    });
    return response.data.data;
  },
};
export default CompanyAPI;
