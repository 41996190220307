import { route } from "quasar/wrappers";
import { setI18nLocale } from "src/boot/i18n";
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from "vue-router";
import { Quasar } from "quasar";
import routes from "./routes";
import { registerEvent } from "src/utils/events";

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

const createHistory = process.env.SERVER
  ? createMemoryHistory
  : process.env.VUE_ROUTER_MODE === "history"
  ? createWebHistory
  : createWebHashHistory;

const Router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes,

  // Leave this as is and make changes in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  history: createHistory(process.env.MODE === "ssr" ? void 0 : process.env.VUE_ROUTER_BASE),
});

Router.beforeEach(async (route) => {
  const locale = route.params.locale;

  // sets the translation language, used by $t( ... )
  setI18nLocale(locale || "en");

  // Sets the quasar language pack
  // responsible for RTL-ing the whole app and changing the labels of quasar components
  const langIso = locale === "en" ? "en-US" : locale;
  try {
    await import(
      /* webpackInclude: /(ar|en-US)\.js$/ */
      "quasar/lang/" + langIso
    ).then((lang) => {
      Quasar.lang.set(lang.default, undefined);
    });
  } catch (err) {
    // Requested Quasar Language Pack does not exist,
    // let's not break the app, so catching error
  }

  // Register an event (gtag, fb pixel, ...) on page load
  // only if the route has an "event" meta field
  registerEvent(route.meta?.event);

  console.log("profiler", window.MiniProfiler);

  if (window.MiniProfiler !== undefined) {
    window.MiniProfiler?.pageTransition();
  }
});

export default Router;
