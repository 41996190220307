import { boot } from "quasar/wrappers";
import { api } from "boot/axios";

import { fBInit } from "src/services/firebase";

import { getMessaging, getToken } from "firebase/messaging";

import {
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseMessagingSenderId,
  firebaseAppId,
  firebaseVapidKey,
} from "src/utils/constants";

const app = fBInit({
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessagingSenderId,
  appId: firebaseAppId,
});

export default boot(async () => {
  const messaging = getMessaging(app);

  try {
    const currentToken = await getToken(messaging, {
      vapidKey: firebaseVapidKey,
    });

    if (!currentToken) {
      console.log("No registration token available. Request permission to generate one.");
      return;
    }

    console.log("Current token for client:", currentToken);

    await sendTokenToServer(currentToken);
  } catch (err) {
    console.log("An error occurred while retrieving token.", err);
  }
});

export async function sendTokenToServer(currentToken) {
  try {
    const res = await api.post("/api/v2/fcm_device_tokens", {
      token: currentToken,
    });
    console.log("Response from server:", res);
  } catch (err) {
    console.log("Error from server:", err);
    // Handle error appropriately
  }
}
export { app };
