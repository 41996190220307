import JumiaAPI from "src/api/Jumia";

export async function signUp({ commit }) {
  commit("setSignUpLoading", true);
  try {
    await JumiaAPI.signUp();
  } finally {
    commit("setSignUpLoading", false);
  }
}
