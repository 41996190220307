import ProductAPI from "src/api/Product";
import CollectionAPI from "src/api/Collection";
import cloneDeep from "lodash/cloneDeep";
import InstagramAPI from "src/api/Instagram";

// append = true; adds to the product list, does not reset it
// append = false; resets the products list
export async function getProducts({ commit }, { payload, append, includeRelatedProducts }) {
  if (!append) commit("updateProductsLoading", true);
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 25,
    order: payload.order || { id: "desc" },
  };

  const query = {
    searchQuery: payload.searchQuery || null,
    includeRelatedProducts,
  };
  const filterOptions = payload.filterOptions || {};

  try {
    let result = await ProductAPI.getProducts(query, filterOptions, pagination);
    if (append) commit("addProducts", result);
    else commit("updateProductsList", result);
  } finally {
    if (!append) commit("updateProductsLoading", false);
  }
}

export async function getAvailableProducts({ commit }, { payload }) {
  commit("updateAvailableProductsLoading", true);
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 25,
  };
  const query = {
    searchQuery: payload.searchQuery || null,
  };

  try {
    let result = await ProductAPI.getProducts(query, {}, pagination);
    commit("updateAvailableProducts", result);
  } finally {
    commit("updateAvailableProductsLoading", false);
  }
}

export async function getProduct({ commit }, id) {
  commit("resetProductDetails");
  commit("updateProductDetailsLoading", true);

  try {
    const response = await ProductAPI.getProduct(id);
    if (!response)
      throw {
        response: {
          status: 404,
          data: { error: { message: "Product not found" } },
        },
      };
    commit("updateProductDetails", response);
    return response;
  } finally {
    commit("updateProductDetailsLoading", false);
  }
}

export async function getMetadata({ commit }) {
  commit("updateMetadataLoadingState", true);

  const pagination = {
    page: 1,
    limit: 1000,
    order: { id: "desc" },
  };
  const query = {
    searchQuery: null,
  };

  try {
    const [metadata, collections] = await Promise.all([
      ProductAPI.fetchMetadata(),
      CollectionAPI.getCollections(query, pagination, null),
    ]);

    const result = {
      ...metadata,
      collections: collections.data,
    };
    commit("updateMetadata", result);
  } finally {
    commit("updateMetadataLoadingState", false);
  }
}

export async function getProductsOutOfStock({ commit }) {
  const filterOptions = {
    outOfStockOnly: true,
  };
  const pagination = {
    page: 1,
    limit: 1,
    order: { id: "desc" },
  };
  const query = {
    searchQuery: null,
  };

  const result = await ProductAPI.getProducts(query, filterOptions, pagination);
  commit("updateProductsOutOfStock", result.metadata.totalCount);
}

export async function getProductsWithVariants({ commit }, { payload, append }) {
  if (!append) commit("updateProductsLoading", true);
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 10,
    order: payload.order || { id: "desc" },
  };
  const query = {
    searchQuery: payload.searchQuery || null,
    view: "details",
    // TODO: return this to true when BE fixes variants logic
    // hide_out_of_stock: true,
  };
  const filterOptions = payload.filterOptions || {};

  try {
    let result = await ProductAPI.getProducts(query, filterOptions, pagination);
    if (append) commit("addProducts", result);
    else commit("updateProductsList", result);
  } finally {
    if (!append) commit("updateProductsLoading", false);
  }
}

export async function addProduct({ commit }, payload) {
  commit("updateAddProductLoadingState", true);
  try {
    const result = await ProductAPI.addProduct(payload);
    return result;
  } finally {
    commit("updateAddProductLoadingState", false);
  }
}

export async function editProduct({ commit }, { id, product, createVariants }) {
  commit("updateProductDetailsLoading", true);
  try {
    const response = await ProductAPI.editProduct(product, id, createVariants);
    commit("updateProductDetails", response);
    return response;
  } finally {
    commit("updateProductDetailsLoading", false);
  }
}

export async function editJumiaProduct({ commit }, { id, product }) {
  commit("updateProductDetailsLoading", true);
  try {
    const response = await ProductAPI.editJumiaProduct(id, product);
    commit("updateProductDetails", response);
    return response;
  } finally {
    commit("updateProductDetailsLoading", false);
  }
}

export async function editProductOptions({ commit }, { id, product }) {
  commit("updateProductDetailsLoading", true);
  try {
    const response = await ProductAPI.editProductOptions(product, id);
    commit("updateProductDetails", response);
    return response;
  } finally {
    commit("updateProductDetailsLoading", false);
  }
}

export async function bulkUpdate({ commit }, { ids, data }) {
  commit("updateProductDetailsLoading", true);
  try {
    const response = await ProductAPI.bulkUpdate(ids, data);
    return response;
  } finally {
    commit("updateProductDetailsLoading", false);
  }
}

export async function bulkDelete({ commit }, { ids }) {
  commit("updateProductsLoading", true);
  try {
    const response = await ProductAPI.bulkDelete(ids);
    return response;
  } finally {
    commit("updateProductsLoading", false);
  }
}

export async function duplicateProduct({ commit, state }, { selectedModules, newTitle, locale, product }) {
  commit("updateProductDetailsLoading", true);
  try {
    let payload = cloneDeep(state.newProduct);
    for (const module of selectedModules) {
      payload = { ...payload, [module]: product[module] };
    }

    payload.basicInfo.name[locale] = newTitle;

    const result = await ProductAPI.duplicateProduct(payload);
    return result.data.id;
  } finally {
    commit("updateProductDetailsLoading", false);
  }
}

export async function deleteProduct({ commit }, id) {
  commit("updateProductDetailsLoading", true);
  try {
    const result = await ProductAPI.deleteProduct(id);
    return result.success;
  } finally {
    commit("updateProductDetailsLoading", false);
  }
}

export async function exportProducts({ commit, rootState }) {
  commit("updateExportLoading", true);
  try {
    const storeName = rootState.auth.company?.name || "Zammit";
    const date = new Date().toDateString().replaceAll(" ", "-");
    await ProductAPI.exportProducts(`${storeName}-products-${date}`);
  } finally {
    commit("updateExportLoading", false);
  }
}

export async function importProducts({ commit }, file) {
  commit("updateImportLoading", true);
  try {
    await ProductAPI.importProducts(file);
  } finally {
    commit("updateImportLoading", false);
  }
}

export async function getInstagramPosts({ state, commit }) {
  commit("updateInstagramLoading", true);
  try {
    const posts = await InstagramAPI.getPosts(state.instagram.token);
    commit("updateInstagramPosts", posts);
  } finally {
    commit("updateInstagramLoading", false);
  }
}

export async function createProductsFromInstagram({ commit }, payload) {
  commit("updateInstagramLoading", true);
  try {
    await InstagramAPI.createProducts(payload);
  } finally {
    commit("updateInstagramLoading", false);
  }
}

export async function updateProductWithRelatedProducts({ commit }, { id, relatedProducts }) {
  commit("updateProductsLoading", true);
  try {
    const response = await ProductAPI.updateProductWithRelatedProducts(id, relatedProducts);
    commit("updateProductWithRelatedProducts", response);
    return response;
  } finally {
    commit("updateProductsLoading", false);
  }
}
