import { api } from "boot/axios";

const apiPrefix = "api/v2";

const JumiaAPI = {
  async signUp() {
    const response = await api.get(`${apiPrefix}/jumia_integrations/login`);
    return response.data;
  },
};

export default JumiaAPI;
