import { api } from "boot/axios";
import { flattenProduct, formatProduct } from "src/api/serializers/productSerializer";
import { downloadFile } from "src/utils";
import { Notify } from "quasar";
import { i18n } from "boot/i18n";

const apiPrefix = "api/v2";
const productPrefix = "products";
const $t = i18n.global.t;

const ProductAPI = {
  async getProducts(query, filterOptions, pagination) {
    const response = await api.get(`${apiPrefix}/${productPrefix}`, {
      params: {
        page: pagination.page,
        limit: pagination.limit,

        order: JSON.stringify(pagination.order),
        ...(query.searchQuery && { search: query.searchQuery }),
        ...{ view: query.view },
        ...{ hide_out_of_stock: query.hide_out_of_stock },
        ...(filterOptions.outOfStockOnly && { out_of_stock: true }),
        ...(filterOptions.hasVariantsOnly && { only_with_variants: true }),
        ...(filterOptions.status && { status: filterOptions.status }),
        ...(filterOptions.collections?.length && {
          collection_ids: JSON.stringify(filterOptions.collections.map((collection) => collection.id)),
        }),

        ...(filterOptions.vendors?.length && {
          vendor: filterOptions.vendors,
        }),
        ...(filterOptions.types?.length && {
          type: JSON.stringify(filterOptions.types),
        }),
        ...(query.includeRelatedProducts && {
          includes: JSON.stringify([{ relation: "related_products" }]),
        }),
      },
    });

    const result = response.data.data;
    return result;
  },

  async uploadImage(image, onUploadProgress) {
    const formData = new FormData();
    formData.append("image", image);

    const response = await api.post(`${apiPrefix}/${productPrefix}/upload_image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });

    return response.data.id;
  },

  async fetchMetadata() {
    const response = await api.get(`${apiPrefix}/${productPrefix}/metadata`);
    const metadata = response.data.metadata;

    // clearing empty strings
    metadata.tags = metadata.tags.filter((tag) => tag);
    metadata.vendors = metadata.vendors.filter((vendor) => vendor);
    metadata.types = metadata.types.filter((type) => type);

    return metadata;
  },

  async getProduct(id) {
    const response = await api.get(`${apiPrefix}/${productPrefix}/${id}`, {
      headers: {
        locale: "all",
      },
      params: {
        includes: JSON.stringify([{ relation: "product_option_values" }, { relation: "product_options" }]),
      },
    });
    const data = response.data.data;
    const payload = formatProduct(data);
    return payload;
  },

  async addProduct(newProduct) {
    const payload = flattenProduct(newProduct);

    const response = await api.post(`${apiPrefix}/${productPrefix}`, payload, {
      headers: { locale: "all" },
    });
    return response.data;
  },

  async duplicateProduct(product) {
    const payload = flattenProduct(product);

    const response = await api.post(`${apiPrefix}/${productPrefix}/duplicate`, payload);
    return response.data;
  },

  async editProduct(product, id, createVariants) {
    const payload = { ...flattenProduct(product), createVariants };
    const response = await api.put(`${apiPrefix}/${productPrefix}/${id}`, payload, {
      headers: {
        locale: "all",
      },
      params: {
        includes: JSON.stringify([{ relation: "product_options" }, { relation: "product_option_values" }]),
      },
    });
    return formatProduct(response.data.data);
  },

  async editJumiaProduct(id, product) {
    const response = await api.put(`${apiPrefix}/${productPrefix}/${id}`, product, {
      headers: {
        locale: "all",
      },
    });
    return response.data.data;
  },

  async editProductOptions(product, id) {
    const payload = flattenProduct(product);
    const response = await api.put(`${apiPrefix}/${productPrefix}/${id}/options`, payload, {
      headers: {
        locale: "all",
      },
      params: {
        includes: JSON.stringify([{ relation: "product_option_values" }, { relation: "product_options" }]),
      },
    });
    return formatProduct(response.data.data);
  },

  async deleteProduct(id) {
    const response = await api.delete(`${apiPrefix}/${productPrefix}/${id}`);
    return response.data;
  },

  async importProducts(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.post(`${apiPrefix}/${productPrefix}/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.success;
  },

  async exportProducts(name) {
    const response = await api.get(`${apiPrefix}/${productPrefix}/export`, {
      responseType: "blob",
    });

    if (response.headers["content-type"] === "text/csv") {
      downloadFile(response.data, `${name}.csv`);
    } else {
      const message = JSON.parse(await response.data.text()).message;
      Notify.create({ message });
    }
  },

  async bulkUpdate(ids, data) {
    const response = await api.put(`${apiPrefix}/${productPrefix}/update_all`, {
      ids,
      ...data,
    });

    return response.data.success;
  },

  async bulkDelete(ids) {
    const response = await api.delete(`${apiPrefix}/${productPrefix}/delete_all`, {
      data: { ids },
    });

    return response.data.success;
  },

  async updateProductWithRelatedProducts(id, data) {
    const payload = {
      related_products: data,
    };
    const response = await api.put(`${apiPrefix}/${productPrefix}/${id}`, payload, {
      params: {
        includes: JSON.stringify([{ relation: "related_products" }]),
      },
    });

    return response.data.data;
  },

  async addProductsToJumia(ids) {
    const response = await api.post(`${apiPrefix}/${productPrefix}/add_products_to_jumia`, { ids });

    if (response.status === 200) {
      Notify.create({
        type: "positive",
        message: $t("products.add_to_jumia.success"),
      });
    } else {
      Notify.create({
        type: "negative",
        message: $t("products.add_to_jumia.error"),
      });
    }

    return response.data.data;
  },
};

export default ProductAPI;
